
import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { LayoutModel, MenuModel } from '@/store/layout-store/types';
import { StructureStatusName, SubMenuItem, TestTypeIdEnum, VideoSource } from '@/models';
import { IS_DEBUG_MODE, IS_SPP, SET_PAGE_ID } from '@/store/list';
import ClickOutside from '@/directives/click-outside';
import {
  LoadingBlend,
  Modal,
  ModalBody,
  ModalButton,
  ModalFooter,
  ModalHeader,
  VideoPlayer
} from '@/shared/components';
import { NeAppWrapper, NeCarousel, NeCarouselItem, NeIcon, NeIconButton } from '@ne/ne-vue2-lib';
import { RouteName } from '@/router/models';
import { Location } from 'vue-router';
import GtMenuCarousel from '@/views/Generator/components/GtMenu/GtMenuCarousel/GtMenuCarousel.vue';
import { GtPageTypes } from '@/config';
import { isSuperUser } from '@/helpers/roles';
import { TranslateResult } from 'vue-i18n';

const AppStore = namespace('AppStore');
const AuthStore = namespace('AuthStore');
const LayoutStore = namespace('LayoutStore');

@Component({
  name: 'GtMenu',
  components: {
    VideoPlayer,
    NeAppWrapper,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton,
    NeIconButton,
    LoadingBlend,
    NeIcon,
    NeCarousel,
    NeCarouselItem,
    GtMenuCarousel
  },
  directives: {
    ClickOutside
  }
})
export default class GtMenu extends Vue {
  @Prop() menu: MenuModel;
  @Prop() categoryKey: string;
  @Prop() isLoaded: boolean;

  isModalOpen = false;
  videoSource: VideoSource | null = null;
  isHelpShown = false;
  gtPageTypes = GtPageTypes;
  isSuperUser = isSuperUser;

  @AppStore.State('lastPageId') lastPageId: number;
  @AppStore.State('pageId') pageId: GtPageTypes;
  @AppStore.State('step') currentStep!: number;
  @AppStore.State('structureStatus') structureStatus: StructureStatusName;
  @AppStore.Mutation(SET_PAGE_ID) setPageId: (pageId: GtPageTypes) => void;
  @AppStore.Getter(IS_DEBUG_MODE) debugMode: boolean;
  @AppStore.Getter(IS_SPP) isSpp: boolean;
  @AuthStore.State('isUserLogged') isUserLogged: boolean;
  @LayoutStore.State('layout') layout: LayoutModel;

  testDataBaseLabel (isSpp: boolean, isSuperUser: boolean): TranslateResult {
    if (isSuperUser) return this.$tc('GT_MENU.testing_materials');
    return isSpp ? this.$tc('GT_MENU.test_database') : this.$tc('GT_MENU.test_database_work_card');
  }

  get subMenuItems (): SubMenuItem[] {
    const testTypeInQuery = this.$route.query?.facets ? (this.$route.query.facets as string).replace('testType-', '') : ''
    const testTabItems = [
      {
        name: this.$t('GT_MENU.preview'),
        iconName: 'magnifier-6',
        route: RouteName.TEST_DATABASE,
        key: 'submenu-test-list'
      },
      {
        name: this.$t('GT_MENU.prepare_test'),
        iconName: 'plus-inverse',
        route: this.$route.name === RouteName.TEST_EDITOR ? RouteName.TEST_EDITOR : RouteName.TEST_COMPOSER,
        testTypeId: testTypeInQuery ? +testTypeInQuery : TestTypeIdEnum.TEST,
        key: 'submenu-test-create'
      },
      {
        name: this.$t('GT_MENU.prepare_work_card'),
        iconName: 'sheet',
        route: this.$route.name === RouteName.TEST_EDITOR ? RouteName.TEST_EDITOR : RouteName.TEST_COMPOSER,
        testTypeId: TestTypeIdEnum.WORK_CARD,
        key: 'submenu-work-card-create'
      }
    ];

    const exerciseTabItems = [
      {
        name: this.$t('GT_MENU.preview'),
        iconName: 'magnifier-6',
        route: RouteName.EXERCISE_DATABASE,
        key: 'submenu-exercise-list'
      },
      {
        name: this.$t('GT_MENU.prepare_exercise'),
        iconName: 'plus-inverse',
        route: RouteName.EXERCISE_COMPOSER,
        key: 'submenu-exercise-create'
      }
    ];
    return this.pageId === GtPageTypes.TEST_DATABASE ? testTabItems.filter(item => !this.isSpp || item.key !== 'submenu-work-card-create') : exerciseTabItems;
  }

  changeSubMenu (item: SubMenuItem) {
    if (!this.isSubTabActive(item)) {
      this.$router.push({
        name: item.route,
        params: item.testTypeId ? { testTypeId: item.testTypeId.toString() } : {}
      });
    }
  }

  // TODO: tmp solution on review. To refactor
  isSubTabActive (item: SubMenuItem): boolean {
    if (item.route === RouteName.TEST_COMPOSER || item.route === RouteName.TEST_EDITOR) {
      return this.$route.params.testTypeId === item.testTypeId!.toString();
    }
    return this.$route.name === item.route;
  }

  isTabActive (tabIndex: GtPageTypes): boolean {
    return tabIndex === this.pageId;
  }

  tabRouteForTestComposer (routeName: string): Location {
    return {
      name: routeName,
      params: {
        categoryKey: this.categoryKey.split('/').join(',')
      },
      query: this.$route.query
    };
  }

  getRouteName (tabIndex: GtPageTypes): RouteName {
    const tabLocations: RouteName[] = [RouteName.TEST_DATABASE, RouteName.EXERCISE_DATABASE];
    return tabLocations[tabIndex];
  }

  shouldAddQueryToLocation (sourceRouteName: string, targetRouteName: string): boolean {
    return sourceRouteName === RouteName.EXERCISE_DATABASE && targetRouteName === RouteName.TEST_COMPOSER;
  }

  getTabRoute (tabIndex: GtPageTypes): void {
    const sourceRouteName = this.getRouteName(this.pageId);
    const targetRouteName = this.getRouteName(tabIndex);
    const defaultLocation = {
      name: targetRouteName,
      params: {
        categoryKey: this.categoryKey.split('/').join(',')
      }
    };
    const location: Location = this.shouldAddQueryToLocation(sourceRouteName, targetRouteName) ? this.tabRouteForTestComposer(targetRouteName) : defaultLocation;
    if (sourceRouteName !== targetRouteName) {
      this.$router.push(location);
    }
  }

  hideHelpMenu (): void {
    this.isHelpShown = false;
  }
}
