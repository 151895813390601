
import { Component, Vue } from 'vue-property-decorator';
import ClickOutside from '@/directives/click-outside';
import {
  NeButton,
  NeCarousel,
  NeCarouselItem
} from '@ne/ne-vue2-lib';
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader } from '@/shared/components';

@Component({
  name: 'GtMenu',
  components: {
    NeCarousel,
    NeCarouselItem,
    NeButton,
    Modal,
    ModalBody,
    ModalButton,
    ModalFooter,
    ModalHeader
  },
  directives: {
    ClickOutside
  }
})
export default class GtMenuCarousel extends Vue {
  isNeonModalOpen = false;
  isOndorioModalOpen = false;

  get cagetoryKey (): string {
    return this.$route.params.categoryKey;
  }

  get isEarlySchoolEducation (): boolean {
    return this.$route.params.categoryKey.includes('szkola-podstawowa-klasy-1-3');
  }

  get isEarlySchoolEducation48 (): boolean {
    return this.$route.params.categoryKey.includes('szkola-podstawowa-klasy-4-8');
  }
}
